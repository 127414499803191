<template>
  <footer>
    <button class="btn_top" :class="showTopBtn" @click="goToTop">
      <img src="@/assets/img/common/top_btn.svg" alt="맨 위로 가기 버튼" />
    </button>
    <!-- inner// -->
    <div class="inner">
      <div class="primary_info">
        <h2 @click.prevent="openFooter">
          <a><img src="@/assets/img/common/logo_gray.svg" alt="푸터로고"/></a>
          <span class="mb_more" :class="showFooter"
            ><img src="@/assets/img/mobile/btn_open_mob.png" alt="모바일푸터더보기버튼"
          /></span>
        </h2>
        <a href="#" @click.prevent="movePage('/contact/inqury')" class="link_iqr_common"
          >문의상담</a
        >
        <div class="only_mobile_opn" :class="showFooter">
          <div class="file_link">
            <strong>Brochure</strong>
            <span class="select_rct footer_select">
              <select name="" id="" v-model="selected2">
                <option :key="index" :value="item.value" v-for="(item, index) in options">
                  {{item.name}}
                </option>
              </select>
            </span>
            <a href="#" @click.prevent="handleSelectedFunction(selected2)" >
              <span v-if="selected2 === '4'"><img :src="require('@/assets/img/common/ic_url.svg')" alt="다운로드아이콘"/></span>             
              <span v-else><img :src="require('@/assets/img/common/ic_download.svg')" alt="다운로드아이콘"/></span>             
            </a>
            <div class="sns">
              <ul>
                <li class="insta"><a href="https://www.instagram.com/enders_official/"></a></li>
                <!-- <li class="utube"><a></a></li>
                <li class="naver"><a></a></li>
                <li class="kakao"><a></a></li> -->
              </ul>
            </div>
          </div>
          <ul>
            <li><strong>(주)엔더스</strong></li>
            <li><address>(06178) 서울특별시 강남구 테헤란로 84길 14 지어로빌딩 11층</address></li>
            <li>
              <ul class="list_messenger">
                <li>
                  <a href="mailto:support@enders.co.kr"
                    ><span><img src="@/assets/img/common/ic_mail.svg" alt="엔더스 메일주소"/></span
                    >&nbsp;&nbsp;support@enders.co.kr</a>
                </li>
                <li>
                  <a href="tel:02-6082-9630"
                    ><span><img src="@/assets/img/common/ic_phone.svg" alt="엔더스 대표전화"/></span
                    >&nbsp;&nbsp;02-6082-9630</a
                  >
                </li>
              </ul>
            </li>
          </ul>
        </div>
      </div>
      <p class="copy_info">copyright © Enders All Rights Reserved.</p>
    </div>
    <!-- //inner -->
  </footer>
</template>

<script>
import axios from "axios";

export default {
  name: "Footer",
  props: {
    myheight: {
      type: Number,
      default: 0
    }
  },
  data() {
    return {
      footerMenu: false,
      options:[
        {name:"선택", value:""},
        {name:"회사소개서", value:'1' },
        {name:"EZEN CLOUD", value:"4" },
      ],
      selected2: '',
    };
  },
  computed: {
    showTopBtn() {
      return this.myheight > 500 ? "active" : "";
    },
    showFooter() {
      return this.footerMenu ? "active" : "";
    }
  },
  methods: {
    movePage(path) {
      if (this.$route.path !== path) {
        this.$router.push(path);
      }
    },
    handleSelectedFunction(item) {  
      if(item === '1'){        
        this.downloadFile();   
        return
      }
      if(item === '4'){
        window.open('https://www.ezencloud.co.kr/');               
      }
      else {
        this.movePage(item);        
      }
    },
    async downloadFile() {
      const { data } = await axios.get("https://www.enders.co.kr/files/company_info.pdf", {
        responseType: "blob"
      });
      console.log(data);
      const url = window.URL.createObjectURL(new Blob([data], {type: 'application/pdf'}));
      console.log(url);
      const downloadLink = document.createElement("a");
      downloadLink.setAttribute("download", "엔더스_회사_소개서.pdf");
      downloadLink.href = url;
      document.body.appendChild(downloadLink);
      downloadLink.click();
      document.body.removeChild(downloadLink);
      downloadLink.remove();
    },
    goToTop() {
      window.scrollTo({
        top: 0,
        left: 0,
        behavior: "smooth"
      });
    },
    openFooter() {
      this.footerMenu = !this.footerMenu;
    }
  }
};
</script>
