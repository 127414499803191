<template>
  <div id="wrap">
    <Header :headStyle="headStyle" :myheight="myheight" />
    <router-view />
    <Footer :myheight="myheight" />
  </div>
</template>

<script>
import Header from '@/components/Header.vue';
import Footer from '@/components/Footer.vue';

export default {
  name: 'Layout',
  components: {
    Header,
    Footer,
  },
  data() {
    return {
      headStyle: '',
      myheight: 0,
    };
  },
  created() {
    this.setHeadStyle();
  },
  mounted() {
    window.addEventListener('scroll', () => {
      this.myheight = window.pageYOffset;
    });
  },
  updated() {
    this.setHeadStyle();
  },
  methods: {
    setHeadStyle() {
      this.headStyle = this.$route.path === '/' ? 'position: absolute; top: 0; left: 0;' : '';
    },
  },
};
</script>
