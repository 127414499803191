<template>
  <!-- header : 공통// -->
  <header :style="headStyle" :class="showStickyHeader">
    <!-- inner// -->
    <div class="inner">
      <h1 class="logo fl">
        <a href="#" @click.prevent="movePage('/')"
          ><img src="@/assets/img/common/logo.svg" alt="엔더스 로고"
        /></a>
      </h1>
      <nav class="gnb">
        <ul class="navbar_link">
          <li @mouseleave="hideSubMenu" :class="mainMenuStyle.service">
            <a href="#" @click.prevent="movePage('/service/ebiz')" @mouseover="showServiceSubMenu"
              >Service</a
            >
            <ul class="list_depth2" :style="ServiceMenuStyle">
              <li><a href="#" @click.prevent="movePage('/service/ebiz')">E-BIZ 서비스</a></li>
              <li>
                <a href="#" @click.prevent="movePage('/service/cloud')">클라우드 트랜스포메이션</a>
              </li>
              <li><a href="#" @click.prevent="movePage('/service/dcx')">디지털 CX · UIUX</a></li>
              <li>
                <a href="#" @click.prevent="movePage('/service/solution')">비즈니스 솔루션</a>
              </li>
            </ul>
          </li>

          <li :class="mainMenuStyle.portfolio">
            <a href="#" @click.prevent="movePage('/portfolio')">Portfolio</a>
          </li>

          <li :class="mainMenuStyle.about">
            <a href="#" @click.prevent="movePage('/about')">About</a>
          </li>

          <li @mouseleave="hideSubMenu" :class="mainMenuStyle.contact">
            <a
              href="#"
              @click.prevent="movePage('/contact/location')"
              @mouseover="showContactSubMenu"
              >Contact</a
            >
            <ul class="list_depth2" :style="ContactMenuStyle">
              <li><a href="#" @click.prevent="movePage('/contact/location')">오시는 길</a></li>
              <li><a href="#" @click.prevent="movePage('/contact/inqury')">문의상담</a></li>
            </ul>
          </li>
          <li @mouseleave="hideSubMenu" :class="mainMenuStyle.crew">
            <a href="#" @click.prevent="movePage('/crew/enderscrew')" @mouseover="showCrewSubMenu"
              >Enders Crew</a
            >
            <ul class="list_depth2" :style="CrewMenuStyle">
              <li><a href="#" @click.prevent="movePage('/crew/enderscrew')">엔더스 크루</a></li>
              <li><a href="#" @click.prevent="movePage('/crew/recruit-process')">지원정보</a></li>
              <li><a href="#" @click.prevent="movePage('/crew/recruit')">모집분야</a></li>
              <li><a href="#" @click.prevent="movePage('/crew/recruit-apply')">크루 지원</a></li>
            </ul>
          </li>
        </ul>
      </nav>
      <a href="#" @click.prevent="movePage('/contact/inqury')" class="link_iqr_common">문의상담</a>
      <!-- 모바일메뉴버튼// -->
      <!-- 0803 : menu_toggle으로 수정하겠습니다-->
      <button class="menu_toggle" @click="openMoMenu">
        <span class="hide">모바일메뉴버튼</span>
        <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 40 40">
          <g id="btn_menu" transform="translate(-304 -10)">
            <rect id="base" width="40" height="40" transform="translate(304 10)" fill="none" />
            <line
              id="선_11"
              data-name="선 11"
              x2="16"
              transform="translate(316 23)"
              fill="none"
              stroke="#3d3d3d"
              stroke-linecap="round"
              stroke-width="2"
            />
            <line
              id="선_12"
              data-name="선 12"
              x2="16"
              transform="translate(316 30)"
              fill="none"
              stroke="#3d3d3d"
              stroke-linecap="round"
              stroke-width="2"
            />
            <line
              id="선_13"
              data-name="선 13"
              x2="16"
              transform="translate(316 37)"
              fill="none"
              stroke="#3d3d3d"
              stroke-linecap="round"
              stroke-width="2"
            />
          </g>
        </svg>
      </button>
      <!-- //모바일메뉴버튼 -->

      <!-- 모바일메뉴컨텐트// -->
      <aside class="mob_nav" :class="moMenuToggle">
        <div class="logo" @click.prevent="movePage('/')">
          <a href="javascript:;"><img src="@/assets/img/common/logo.svg" alt="엔더스 로고"/></a>
        </div>
        <button class="menu_close" @click="openMoMenu">
          <span class="hide">모바일메뉴 닫기버튼</span>
          <img src="@/assets/img/mobile/btn_close.png" alt="모바일 메뉴 닫기">
        </button>
        <nav>
          <ul class="mob_navlink">
            <li>
              <a>Service</a>
              <ul class="mob_sublink">
                <li><a href="#" @click.prevent="movePage('/service/ebiz')">E-BIZ 서비스</a></li>
                <li>
                  <a href="#" @click.prevent="movePage('/service/cloud')"
                    >클라우드 트랜스포메이션</a
                  >
                </li>
                <li><a href="#" @click.prevent="movePage('/service/dcx')">디지털 CX · UIUX</a></li>
                <li>
                  <a href="#" @click.prevent="movePage('/service/solution')">비즈니스 솔루션</a>
                </li>
              </ul>
            </li>

            <li><a href="#" @click.prevent="movePage('/portfolio')">Portfolio</a></li>

            <li><a href="#" @click.prevent="movePage('/about')">About</a></li>

            <li>
              <a>Contact</a>
              <ul class="mob_sublink">
                <li><a href="#" @click.prevent="movePage('/contact/location')">오시는 길</a></li>
                <li><a href="#" @click.prevent="movePage('/contact/inqury')">문의상담</a></li>
              </ul>
            </li>

            <li>
              <a>Enders Crew</a>
              <ul class="mob_sublink">
                <li><a href="#" @click.prevent="movePage('/crew/enderscrew')">엔더스 크루</a></li>
                <li><a href="#" @click.prevent="movePage('/crew/recruit-process')">지원정보</a></li>
                <li><a href="#" @click.prevent="movePage('/crew/recruit')">모집분야</a></li>
                <li><a href="#" @click.prevent="movePage('/crew/recruit-apply')">크루 지원</a></li>
              </ul>
            </li>
          </ul>
        </nav>
      </aside>
      <!-- //모바일메뉴컨텐트 -->
    </div>
    <!-- //inner -->
  </header>
  <!-- //header : 공통 -->
</template>

<script>
export default {
  name: "Header",
  props: {
    headStyle: {
      type: String,
      default: ""
    },
    myheight: {
      type: Number,
      default: 0
    }
  },
  data() {
    return {
      ServiceMenuStyle: "display:none;",
      ContactMenuStyle: "display:none;",
      CrewMenuStyle: "display:none;",
      isMoMenu: false,
      mainMenuStyle: {
        service: "",
        portfolio: "",
        about: "",
        contact: "",
        crew: ""
      }
    };
  },
  computed: {
    moMenuToggle() {
      if (this.isMoMenu) {
        return "active";
      }
      return "";
    },
    showStickyHeader() {
      return this.myheight > 400 ? "sticky" : "";
    }
  },
  watch: {
    "$route.path": function move() {
      this.resetHeaderActive();
      this.setHeaderActive(this.$route.path);
    }
  },
  methods: {
    showServiceSubMenu() {
      this.ServiceMenuStyle = "display: block;";
    },
    showContactSubMenu() {
      this.ContactMenuStyle = "display: block;";
    },
    showCrewSubMenu() {
      this.CrewMenuStyle = "display: block;";
    },
    hideSubMenu() {
      this.ServiceMenuStyle = "display: none;";
      this.ContactMenuStyle = "display: none;";
      this.CrewMenuStyle = "display: none;";
    },
    movePage(path) {
      this.hideSubMenu();
      this.isMoMenu = false;
      document.body.classList.remove("active");
      if (this.$route.path !== path) {
        this.$router.push(path);
      }
    },
    openMoMenu() {
      this.isMoMenu = !this.isMoMenu;
      // eslint-disable-next-line no-unused-expressions
      this.isMoMenu
        ? document.body.classList.add("active")
        : document.body.classList.remove("active");
    },
    setHeaderActive(path) {
      const pathArr = path.split("/");
      this.mainMenuStyle[pathArr[1]] = "active";
    },
    resetHeaderActive() {
      this.mainMenuStyle.service = "";
      this.mainMenuStyle.portfolio = "";
      this.mainMenuStyle.about = "";
      this.mainMenuStyle.contact = "";
      this.mainMenuStyle.crew = "";
    }
  }
};
</script>

<style scoped></style>
